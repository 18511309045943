import api from "@/api";
import { CartItem } from "@/api/modules/cart";
import { UmsGenerateOrderByPoint } from "@/api/modules/order";
import BreadCrumbs from "@/components/BreadCrumbs";
import { MetaRow } from "@/models/type";
import { parseTime, sharer } from "@/utils";
import { UmsAddress } from "@/views/address";
import { ElNotification } from "element-plus";
import Swiper from "swiper";
import { setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import './index.scss'

export default class SanchuangDetail extends Vue {
  public detailId = 0
  public detailObj: {product?: ProductParam, skuStockList?: SkuStockList[], pmsCommentList?: any[]} = {
  }
  public goodList = [
    {
      id: 60,
      product: {
        title: '團扇手作',
        name: '團扇手作',
        skuStockList: [{id: 1, title: '團扇手作', pic: '/img/sanchuang/2.jpg'}],
        detailHtml: [],
        albumPics: '',
        description: '團扇，又稱宮扇、紈扇，是中國漢族傳統工藝品及藝術品，是一種圓形有柄的扇子，它代表着團圓友善、吉祥如意。材料包附有扇子、乾花、膠水等製作工具，完成後，就會是一本既美觀又實用，而且充滿文化氣息的筆記簿。請參考影片教學，如有困難，可於Facebook或微信留言尋求協助。如有興趣，歡迎蒞臨到本空間購買。',
        showImgUrl: '/img/sanchuang/2.jpg',
        price: 30,
      }
    },
    {
      id: 58,
      product: {
        title: '香囊手作',
        name: '香囊手作',
        skuStockList: [{id: 1, title: '香囊手作', pic: '/img/sanchuang/3.jpg'}],
        detailHtml: ['/img/sanchuang/3-1.jpg'],
        albumPics: '',
        description: '香囊是古代漢族勞動婦女創造的一種民間刺繡工藝品。是以男耕女織為標誌的古代漢族農耕文化的產物，是越千年而餘緒未泯的漢族傳統文化的遺存和再生。材料包附有香料包、絲綢布、繩等等製作工具，既簡單又實用。請參考影片教學，如有困難，可於Facebook或微信留言尋求協助。如有興趣，歡迎蒞臨到本空間購買。',
        showImgUrl: '/img/sanchuang/3.jpg',
        price: 20,
      }
    },
    {
      id: 57,
      product: {
        title: '中式線裝書手作（節日）',
        name: '中式線裝書手作（節日）',
        skuStockList: [{id: 1, title: '中式線裝書手作（節日）', pic: '/img/sanchuang/1.jpg'}],
        detailHtml: ['/img/sanchuang/1-1.jpg'],
        albumPics: '',
        description: '中式線裝書以中國傳統節日為主題，封面插畫描繪中華節日的大雜匯。內頁方面，有中國傳統節日的插畫以及節日的農曆日期，每兩個節日的插畫之間都附有數張單行紙。完成後，就會是一本既美觀又實用，而且充滿文化氣息的筆記簿。請參考影片教學，如有需要，可於Facebook或微信留言查詢。如有興趣，歡迎蒞臨到本空間購買。',
        showImgUrl: '/img/sanchuang/1.jpg',
        price: 20,
      }
    },
    {
      id: 56,
      product: {
        title: '中式線裝書手作（廿四節氣）',
        name: '中式線裝書手作（廿四節氣）',
        skuStockList: [{id: 1, title: '中式線裝書手作（廿四節氣）', pic: '/img/sanchuang/4.jpg'}] ,
        detailHtml: ['/img/sanchuang/4-1.jpg'],
        albumPics: '',
        description: '中式線裝書以廿四節氣為主題，封面插畫描繪春夏秋冬下的長城。內頁方面，有廿四節氣的插畫以及相關詩句，每兩個節氣的插畫之間都附有數張單行紙。完成後，就會是一本既美觀又實用，而且充滿文化氣息的筆記簿。請參考影片教學，如有需要，可於Facebook或微信留言查詢。如有興趣，歡迎蒞臨到本空間購買。',
        showImgUrl: '/img/sanchuang/4.jpg',
        price: 20,
      }
    },
  ]
  public activeIndex = 0
  public swiper:any = {}
  public swiperIndex = 0
  public swiperEnd = false

  public showImgUrl = ''
  public contentType = 1

  public quantity = 1

  public skuId = 0
  public skuIndex = 0
  public showType = 0

  public tableData: Array<any> = []
  public tableDataMine: Array<any> = []
  public addressShow = false

  public addressList: UmsAddress[] = []

  public pointObj: UmsGenerateOrderByPoint = {

  }

  url = ''
  srcList: string[] = []

  pageNum = 1
  pageSize = 10
  total = 10
  pageNumMine = 1
  pageSizeMine = 10
  totalMine = 10

  metaList: Array<MetaRow> = []

  meta = setup(() => {
    const { meta } = useMeta({
      meta: this.metaList
    })
    return meta
  })

  /**
   * @description 商品轮播图
   * @author Jinx
   * @date 2021-10-26 09:51:12
   * @memberof SanchuangDetail
   */
  swiperInit(): void {
    this.$nextTick(() => {
      this.swiper = new Swiper('.swiper-container', {
        slidesPerView: 6,
        spaceBetween: 30,
        slidesPerGroup: 6,
        on: {
          slideChangeTransitionEnd: this.onSlideChangeTransitionEnd
        }
      })
    })
  }

  /**
   * @description 主dom
   * @author Jinx
   * @date 2021-10-26 09:51:02
   * @returns {*}  {JSX.Element}
   * @memberof SanchuangDetail
   */
  mainDom(): JSX.Element {
    const length = this.goodList[this.activeIndex].product?.albumPics?.split(',').length as number
    const price = this.detailObj.skuStockList?.[this.skuIndex]?.price as number
    return <div class="sanchuang-detail-main">
      <div style={{backgroundImage: 'url(/img/sanchuang/bg1.jpg)',backgroundSize: '100% 100%',backgroundRepeat: 'no-repeat', padding:'20px'}} > 
        <el-row>
          <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div class="main-left">
              <img src={this.goodList[this.activeIndex].product.showImgUrl} alt="" />
              {/* <div class="main-left-swiper">
                <div class="swiper-container">
                  <div class="swiper-wrapper">
                    {
                      this.detailObj.product?.albumPics?.split(',').map((item: string) => <div class="swiper-slide">
                        <img src={item} alt="" onClick={() => this.showImg(item)} />
                      </div>)
                    }
                  </div>
                </div>
                <div
                  class="el-icon-arrow-left pointer swiper-left"
                  style={{
                    display: !this.swiperIndex || length <= 6 ? 'none' : 'flex'
                  }}
                  onClick={this.prev}
                />
                <div
                  class="el-icon-arrow-right pointer swiper-right"
                  style={{
                    display: this.swiperEnd || length <= 6 ? 'none' : 'flex'
                  }}
                  onClick={this.next}
                />
              </div> */}
            </div>
          </el-col>
          <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div class="main-right">
              <div class="main-right-header">
                <div class="main-right-header-title">{this.goodList[this.activeIndex].product?.name}</div>
                {
                  this.goodList[this.activeIndex].product?.name
                    && <div class="main-right-header-fun">
                      <share-network
                        network="facebook"
                        url={window.location.href}
                        title={this.goodList[this.activeIndex].product?.name}
                        description={this.goodList[this.activeIndex].product?.description}
                      >
                        <div class="facebook"/>
                      </share-network>
                      <div class="wechat" onClick={() => sharer(2)}/>
                      <share-network
                        network="whatsapp"
                        url={window.location.href}
                        title={this.goodList[this.activeIndex].product?.name}
                        description={this.goodList[this.activeIndex].product?.description}
                      >
                        <div class="phone"/>
                      </share-network>
                  </div>
                }
              </div>
              <p> HK$ { this.goodList[this.activeIndex].product?.price }</p>
              <p>{this.goodList[this.activeIndex].product?.description}</p>
              <div class="main-right-sku">
                <div class="main-right-sku-title">分類</div>
                <div class="main-right-sku-list">
                  {this.goodList[this.activeIndex].product.skuStockList?.map((item, index) => <div
                    class={item.id === this.skuId ? 'active' : ''}
                    onClick={() => this.skuClick(item.id as number, index)}
                  >
                    <img src={item.pic} alt="" />
                    <span>{item.title}</span>
                  </div>)}
                </div>
              </div>
              {/* <div class="main-right-bottom">
                <div class="main-right-bottom-price">{this.showType ? '积分：' : 'HK$'} {price * this.quantity}</div>
                <el-input-number v-model={this.quantity} min={1} max={this.detailObj.skuStockList?.[this.skuIndex].stock} />
                {
                  this.showType
                    ? <div class="main-right-bottom-shop" onClick={this.exchange}>
                        <span>立即兑换</span>
                        <i class="el-icon-arrow-right" />
                      </div>
                    : <div class="main-right-bottom-shop" onClick={this.toCard}>
                        <i class="el-icon-shopping-cart-2" />
                        <span>加入购物车</span>
                      </div>
                }
              </div> */}
              {/* <div class="main-right-teacher">
                <img src={this.detailObj.product?.artistImg} alt="" />
                <div class="main-right-teacher-content">
                  <div class="title">{this.detailObj.product?.artistName}</div>
                  <div class="introduce">{this.detailObj.product?.artistIntroduce}</div>
                </div>
              </div> */}
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  }

  skuClick(id: number, index: number): void {
    this.skuId = id
    this.skuIndex = index
  }

  productDetail(): JSX.Element {
    return <div class="content">
      <div class="content-title">
        <div class={this.contentType === 1 ? 'active' : ''} onClick={() => this.contentChange(1)}>
          <span>作品詳情</span>
          <span />
        </div>
        {/* <div class={this.contentType === 2 ? 'active' : ''} onClick={() => this.contentChange(2)}>
          <span>评论（{this.detailObj.pmsCommentList?.length}）</span>
          <span />
        </div> */}
      </div>
      {this.contentType === 1 &&
        <div class="content-detail">
          {this.detailObj.product?.detailHtml?.split(',').map(item => {
            return <img class="htmlimg" src={item} alt="" />
          })}
        </div>
      }
      {this.contentType === 2 &&
        <div>
          <div class="content-reply">我的评论</div>
          {this.tableDataMine.map((item, index) => <div
            class="mall_comment-list"
          >
            <div class="mall_comment-list-info">
              <img src={item.icon} alt="" />
              <span>{item.username}</span>
            </div>
            <div class="mall_comment-list-right">
              <div class="mall_comment-list-header">
                <div class="mall_comment-list-header-content">
                  <p>{item.content}</p>
                  <div class="mall_comment-list-time">{parseTime(new Date(item.createTime).getTime())}</div>
                </div>
              </div>
              <div class="mall_comment-list-img">
                {item.pics?.split(',')?.map((i: string) => <img src={i} alt="" onClick={() => this.showImage(item.pics, i)} />)}
              </div>
              {
                item.replayContent
                  && <div class="mall_comment-list-reply">
                    <div class="reply-title">道义空间回复：</div>
                    <div class="reply-content">{ item.replayContent }</div>
                  </div>
              }
            </div>
          </div>)}
          {this.tableDataMine.length > 0 && <el-pagination
            class="sanchuang-detail-pagination"
            onCurrentChange={this.handleCurrentChangeMine}
            currentPage={this.pageNumMine}
            pageSize={this.pageSizeMine}
            layout="prev, pager, next, total"
            total={ this.totalMine }
          />}
          <div class="content-reply">其他评论</div>
          {this.tableData.map((item, index) => <div
            class="mall_comment-list"
          >
            <div class="mall_comment-list-info">
              <img src={item.icon} alt="" />
              <span>{item.username}</span>
            </div>
            <div class="mall_comment-list-right">
              <div class="mall_comment-list-header">
                <div class="mall_comment-list-header-content">
                  <p>{item.content}</p>
                  <div class="mall_comment-list-time">{parseTime(new Date(item.createTime).getTime())}</div>
                </div>
              </div>
              <div class="mall_comment-list-img">
                {item.pics?.split(',')?.map((i: string) => <img src={i} alt="" onClick={() => this.showImage(item.pics, i)} />)}
              </div>
              {
                item.replayContent
                  && <div class="mall_comment-list-reply">
                    <div class="reply-title">道义空间回复：</div>
                    <div class="reply-content">{ item.replayContent }</div>
                  </div>
              }
            </div>
          </div>)}
          {this.tableData.length > 0 && <el-pagination
            class="sanchuang-detail-pagination"
            onCurrentChange={this.handleCurrentChange}
            currentPage={this.pageNum}
            pageSize={this.pageSize}
            layout="prev, pager, next, total"
            total={ this.total }
          />}
        </div>
      }
    </div>
  }

  handleCurrentChange(pageNum: number): void {
    this.pageNum = pageNum
    this.getList()
  }
  handleCurrentChangeMine(pageNum: number): void {
    this.pageNumMine = pageNum
    this.getList()
  }

  showImage(srcList: string, url: string): void {
    this.url = url
    this.srcList = srcList.split(',')
    this.imgViewerShow()
  }

  imgViewerShow(): void {
    const __img =  this.$refs.img as any
    __img.showViewer = true
  }

  imgViewerDom(): JSX.Element {
    return <el-image
      ref="img"
      style="width: 0; height: 0; z-index: 54188;"
      src={ this.url }
      preview-src-list={ this.srcList }
    >
    </el-image>
  }

  exchange(): void {
    this.addressShow = true
  }

  toCard(): void {
    const obj: CartItem = {}
    obj.price = this.detailObj.skuStockList?.[this.skuIndex]?.price
    obj.productCategoryId = this.detailObj.product?.productCategoryId as number
    obj.productId = this.detailObj.product?.id
    obj.productName = this.detailObj.skuStockList?.[this.skuIndex]?.title
    obj.productPic = this.detailObj.skuStockList?.[this.skuIndex]?.pic
    obj.productSn = this.detailObj.skuStockList?.[this.skuIndex]?.skuCode
    obj.productSkuCode = this.detailObj.skuStockList?.[this.skuIndex]?.skuCode
    obj.productSkuId = this.detailObj.skuStockList?.[this.skuIndex]?.id
    obj.quantity = this.quantity
    api.cartAdd(obj).then(res => {
      ElNotification({
        title: '成功',
        type: 'success',
        message: '添加购物车成功'
      })
      this.$router.push({
        name: 'ShopCard'
      })
    })
  }

  contentChange(status: number): void {
    this.contentType = status
    if(status === 2) {
      this.getList()
    }
  }

  getList(): void {
    Promise.all([
      api.getComments({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        productId: this.detailId,
        isMine: 1
      }),
      api.getComments({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        productId: this.detailId,
        isMine: 0
      })
    ]).then(res => {
      this.tableDataMine = res[0].data.list
      this.pageNumMine = res[0].data.pageNum
      this.pageSizeMine = res[0].data.pageSize
      this.totalMine = res[0].data.total
      this.tableData = res[1].data.list
      this.pageNum = res[1].data.pageNum
      this.pageSize = res[1].data.pageSize
      this.total = res[1].data.total
    })
  }

  onSlideChangeTransitionEnd(): void {
    const __swiper = this.swiper as Swiper
    this.swiperIndex = __swiper.activeIndex
    this.swiperEnd = __swiper.isEnd
  }

  next(): void {
    const __swiper = this.swiper as Swiper
    __swiper.slideNext()
  }
  prev(): void {
    const __swiper = this.swiper as Swiper
    __swiper.slidePrev()
  }

  showImg(str: string): void {
    this.showImgUrl = str
  }


  getDetail(): void {
    api.productDetail(this.detailId).then(res => {
      this.detailObj = res.data
      this.skuId = res.data.skuStockList?.[0]?.id
      this.showImgUrl = res.data.product?.albumPics?.split(',')[0]
      this.swiperInit()
      this.getMeta()
    })
  }

  getMeta(): void {
    this.metaList.splice(0, this.metaList.length)
    this.metaList.push(
      {property: 'og:title', content: this.detailObj.product?.name as string},
      {property: 'og:description', content: this.detailObj.product?.description as string},
      {property: 'og:image', content: this.detailObj.product?.coverImg as string}
    )
  }

  created(): void {
    const __query = this.$route.query as any
    this.detailId = +this.$route.params?.id
    this.showType = +__query?.type
    const index =  this.goodList.findIndex(item => item.id == this.detailId)
    index != -1 ? this.activeIndex = index : ''
    // 获取地址列表
    api.memberAddressList().then(res => {
      this.addressList = res.data
      this.pointObj.addressId = res.data.find((item: UmsAddress) => item.defaultStatus === 1)?.id
    })
  }

  goBack(): void {
    this.$router.push({
      name: 'MineIntegral'
    })
  }

  addressClose(): void {
    this.addressShow = false
  }

  chooseAddress(id: number): void {
    this.pointObj.addressId = id
  }

  pointSubmit() {
    this.addressClose()
    this.pointObj.productId = this.detailId
    this.pointObj.skuId = this.detailObj.skuStockList?.[this.skuIndex]?.id
    api.generateOrderByPoint(this.pointObj).then(() => {
      ElNotification({
        type: 'success',
        message: '兑换成功'
      })
      setTimeout(() => {
        this.$router.push({
          name: 'MineOrder'
        })
      }, 1000)
    })
  }

  render(): JSX.Element {
    return <div class="sanchuang-detail">
      {
        this.showType
          ? <div class="sanchuang-detail-back" onClick={this.goBack}>
              <i class="el-icon-arrow-left"/>
              <span>返回</span>
            </div>
          : <BreadCrumbs class="sanchuang-detail-header" />
      }
      {this.mainDom()}
      {this.productDetail()}
      {this.imgViewerDom()}
      <el-drawer
        title="选择收货地址"
        v-model={this.addressShow}
        direction="rtl"
        append-to-body
        before-close={this.addressClose}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            padding: '20px'
          }}
        >
          {this.addressList.map(item => <div
            class={[
              'address-list',
              this.pointObj.addressId === item.id ? 'address-list-active' : 'address-list-primary'
            ]}
            onClick={() => this.chooseAddress(item.id as number)}
          >
            <div class="address-list-header">
              <div class="address-list-header-name">{item.name}</div>
              <div class="address-list-header-phone">{item.phoneNumber}</div>
            </div>
            <p>{item.province} {item.city} {item.region} {item.detailAddress}</p>
          </div>)}
          <div class="address-btn" onClick={this.pointSubmit}>确定</div>
        </div>
      </el-drawer>
    </div>
  }
  mounted(): void {
    this.getDetail()
  }
}

export interface ProductParam  {
  albumPics?: string // 画册图片，连产品图片限制为5张，以逗号分割
	artistId?: number // 艺术家id
	artistImg?: string // 艺术家图片
	artistIntroduce?: string // 艺术家介绍
	artistName?: string // 艺术家名字
	brandId?: number
	brandName?:string // 品牌名称
	coverImg?:string // 封面
	deleteStatus?: number // 删除状态：0->未删除；1->已删除
	description?:string // 商品描述
	detailDesc?:string
	detailHtml?:string // 产品详情网页内容
	detailMobileHtml?:string // 移动端网页详情
	detailTitle?:string
	feightTemplateId?: number
	giftGrowth?: number // 赠送的成长值
	giftPoint?: number // 赠送的积分
	id?: number
	keywords?:string
	lowStock?: number // 库存预警值
	mainImg?:string // 主图
	memberPriceList?: Array<MemberPriceList> // 商品会员价格设置
	name?:string
	newStatus?: number // 新品状态:0->不是新品；1->新品
	note?:string
	originalPrice?: number // 市场价
	pic?:string
	prefrenceAreaProductRelationList?: Array<PrefrenceAreaProductRelationList> // 优选专区和商品的关系
	previewStatus?: number // 是否为预告商品：0->不是；1->是
	price?: number
	productAttributeCategoryId?: number
	productAttributeValueList?: Array<ProductAttributeValueList> // 商品参数及自定义规格属性
	productCategoryId?: number | string
	productCategoryName?:string // 商品分类名称
	productFullReductionList?: Array<ProductFullReductionList> // 商品满减价格设置
	productLadderList?: Array<ProductLadderList> // 商品阶梯价格设置
	productSn?:string // 货号
	promotionEndTime?:string // 促销结束时间
	promotionPerLimit?: number // 活动限购数量
	promotionPrice?: number // 促销价格
	promotionStartTime?:string // 促销开始时间
	promotionType?: number // 	促销类型：0->没有促销使用原价;1->使用促销价；2->使用会员价；3->使用阶梯价格；4->使用满减价格；5->限时购
	publishStatus?: number // 上架状态：0->下架；1->上架
	recommandStatus?: number // 推荐状态；0->不推荐；1->推荐
	sale?: number // 销量
	serviceIds?:string // 以逗号分割的产品服务：1->无忧退货；2->快速退款；3->免费包邮
	skuStockList: Array<SkuStockList> // 商品的sku库存信息
	sort?: number // 排序
	stock?: number // 库存
	subTitle?:string // 副标题
	subjectProductRelationList?: Array<SubjectProductRelationList> // 专题和商品关系
	type?: number // 商品类型：0 商城商品 1积分商品
	unit?:string // 单位
	usePointLimit?: number // 限制使用的积分数
	verifyStatus?: number // 审核状态：0->未审核；1->审核通过
	weight?: number // 商品重量，默认为克
}


export interface MemberPriceList {
  id?: number
  memberLevelId?: number
  memberLevelName?:string
  memberPrice?: number // 会员价格
  productId?: number
}

export interface PrefrenceAreaProductRelationList {
  id?: number
  prefrenceAreaId?: number
  productId?: number
}

export interface ProductAttributeValueList {
  id?: number
  productAttributeId?: number
  productId?: number
  value?: string // 手动添加规格或参数的值，参数单值，规格有多个时以逗号隔开
}
export interface ProductFullReductionList {
  fullPrice?: number
  id?: number
  productId?: number
  reducePrice?: number
}
export interface ProductLadderList {
  count?: number // 满足的商品数量
  discount?: number // 折扣
  id?: number
  price?: number // 折后价格
  productId?: number
}
export interface SkuStockList {
  id?: number
  lockStock?: number // 锁定库存
  lowStock?: number // 预警库存
  pic?:string // 展示图片
  price?: number
  productId?: number
  promotionPrice?: number
  sale?: number
  skuCode?:string
  spData?:string
  stock?: number
  title?: string
}
export interface SubjectProductRelationList {
  id?: number
  productId?: number
  subjectId?: number
}


